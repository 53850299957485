import React, { Fragment, useEffect, useState, useMemo } from "react";
import { apiInvestments, getInvestmentDetail } from "../apis/apis";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useHistory } from "react-router-dom";
import { Close, Search } from "@mui/icons-material";
import { Button } from "@mui/material";
import { toLocalePrice } from "../../utils/StringUtil";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { TableHeadButton } from "../../components/ui/TableHeadButton";

const thBtnStyle = {
  border: "none",
  outline: "none",
};

const InvestmentContainer = () => {
  const history = useHistory();

  const [page, setPage] = useState(1);
  const [investmentList, setInvestmentList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, "0");
    return `${month} ${day}, ${year}`;
  }

  const getInvestments = async (params = {}) => {
    params.pageNumber = 1;
    params.title = searchTerm.trim();
    params.recLimit = 0;
    const data = await apiInvestments(params);

    if (data.status) {
      const { opportunities } = data.data;
      setInvestmentList(opportunities);
    } else {
    }
  };

  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'ascending',
  });

  const sortedInvestmentList = useMemo(() => {
    if (sortConfig.key) {
      return [...investmentList].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1;
        }
        return 0;
      });
    }
    return investmentList;
  }, [investmentList, sortConfig]);
  

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    } else if (sortConfig.key === key && sortConfig.direction === 'descending') {
      direction = 'ascending';
    }
    setSortConfig({ key, direction });
  };


  useEffect(() => {
    if (searchTerm.length < 3) {
      if (searchTerm.trim() === "") {
        getInvestments();
      } else {
        return;
      }
    } else {
      getInvestments();
    }
    setPage(1);
  }, [searchTerm]);

  useEffect(() => {
    getInvestments();
  }, []);

  const onInvestorRowClick = async (ioRid) => {
    history.push(`/user/investment-detail/${ioRid}`);
  };

  return (
    <div className="card p-4">
      <h3 className="fs-x-large-semibold">Investments</h3>

      <div className="d-flex justify-content-between align-items-center my-4">
        <div className="d-flex align-items-center p-3 bg-white rounded-pill form-control w-auto">
          <Search fontSize="small" color="secondary" />
          <input
            type="text"
            placeholder="Companies, Funds, Leads"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ border: "none", outline: "none" }}
          />
          <Close
            fontSize="small"
            color="secondary"
            className="custom-link"
            onClick={() => setSearchTerm("")}
          />
        </div>

        <div className="d-flex gap-2 align-items-center">
          {/* <Button variant="outlined" color="secondary">Filter</Button>
          <Button variant="outlined" color="secondary">Export CSV</Button> */}
          {/* <Button variant="contained" className='px-4 py-2' color="primary" disableElevation>Add Investment</Button> */}
        </div>
      </div>

      <table className="table p-0 m-0">
        <tr className="fs-med-regular text-muted">
          <th scope="col">
            <TableHeadButton
              label="Company / Scheme"
              activeUp={sortConfig.key === 'compTitle' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'compTitle' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('compTitle')}
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Status"
              activeUp={sortConfig.key === 'oppStatusIndex' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'oppStatusIndex' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('oppStatusIndex')}
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Demat Status"
              activeUp={sortConfig.key === 'nsdlCdsl' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'nsdlCdsl' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('nsdlCdsl')}
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Investment Date"
              activeUp={sortConfig.key === 'fundTransferredDate' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'fundTransferredDate' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('fundTransferredDate')}
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Invested"
              activeUp={sortConfig.key === 'commitment' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'commitment' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('commitment')}
              leftAlign
              // hideControls
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Market Value"
              activeUp={sortConfig.key === 'netValue' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'netValue' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('netValue')}
              leftAlign
              // hideControls
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Multiple"
              activeUp={sortConfig.key === 'multiple' && sortConfig.direction === 'ascending'}
              activeDown={sortConfig.key === 'multiple' && sortConfig.direction === 'descending'}
              onClick={() => handleSort('multiple')}
              leftAlign
              // hideControls
            />
          </th>
        </tr>
        <tr className="fs-med-semibold bg-light">
          <td>
            <p className="bg-light p-0 m-0">
              {investmentList.length} Investments
            </p>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <p className="bg-light p-0 m-0 text-end">
              {toLocalePrice(
                investmentList.reduce((acc, obj) => acc + obj.commitment, 0.0)
              )}
            </p>
          </td>
          <td>
            <p className="bg-light p-0 m-0 text-end">
              {toLocalePrice(
                investmentList.reduce((acc, obj) => acc + obj.netValue, 0.0)
              )}
            </p>
          </td>
          <td></td>
        </tr>
        {sortedInvestmentList.map((data, key) => {
          return (
            <Fragment key={key}>
              <tr
                className="border-bottom pointer row-hover"
                onClick={(e) => onInvestorRowClick(data.ioRid)}
              >
                <td>
                  <div className="d-flex gap-2 align-items-center p-0 mx-0 my-1">
                    <div className="thumbnail-img">
                      <img
                        src={
                          data.compLogo
                            ? process.env.REACT_APP_BASE_URL +
                            "/" +
                            data.compLogo
                            : process.env.PUBLIC_URL + "/img/placeholder.png"
                        }
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src =
                            process.env.PUBLIC_URL + "/img/placeholder.png";
                        }}
                        className="thumbnail-img"
                        alt="company-banner"
                      />
                    </div>
                    <div className="d-flex flex-column ps-1">
                      <p className="fs-6 p-0 m-0">{data.compTitle}</p>
                      <p className="fs-normal-medium text-muted p-0 m-0">
                        {data.oppTitle}
                      </p>
                    </div>
                  </div>
                </td>
                <td>
                  <p className="p-0 m-0">
                    <span
                      className={` fs-normal-medium inv-status ${data.oppStatusIndex === 1503 ? "live" : "closing"
                        }`}
                    >
                      {data.oppStatusIndex === 1503 && "Live"}
                      {data.oppStatusIndex === 1502 && "Closing"}
                    </span>
                  </p>
                </td>
                <td>
                  <p className="p-0 m-0">
                    {data.nsdlCdsl === null ? (
                      <span className="fs-normal-medium demat-status isescrow">
                        In Escrow
                      </span>
                    ) : (
                      <>
                        <span className="fs-normal-medium demat-status dematerialized">
                          Dematerialized
                        </span>
                        <div className="d-flex flex-column fs-normal-med mt-1">
                          <div>CDSL ISIN: {data.isin}</div>
                          <div>Units: {data.units}</div>
                        </div>
                      </>
                    )}
                  </p>
                </td>
                <td>
                  <p className="p-0 m-0">
                    {data.fundTransferredDate &&
                      formatDate(data.fundTransferredDate)}
                  </p>
                </td>
                <td>
                  <p className="text-end p-0 m-0">
                    {toLocalePrice(data.commitment)}
                    {/* {data.oppStatusIndex === 1502 ? <AddCircleIcon className="custom-link ms-1" onClick={() => history.push("/user/company-pdp/" + data.oppRid)} /> : ''} */}
                  </p>
                </td>
                <td>
                  <p className="text-end p-0 m-0">
                    {toLocalePrice(data.netValue)}
                  </p>
                </td>
                <td>
                  <p className="text-end p-0 m-0">
                    {toLocalePrice(data.multiple)}x
                  </p>
                </td>
              </tr>
            </Fragment>
          );
        })}
      </table>
    </div>
  );
};

export default InvestmentContainer;
