import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import "dayjs/locale/en-gb";
import { Divider , IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { apiDematInfo, apiSaveBankInfo, apiSaveBasicDetail, apiUpdateInvestor, getDataDictionaryValues, getUserDetails, uploadFile } from "../apis/apis";
import { SNACKBAR_SUCCESS, SNACKBAR_ERROR, DD_TYPE_INVESTOR_TYPE, DD_TYPE_BANK_TYPE, DD_TYPE_DP_NAMES } from "../../utils/constants/constants";
import "react-phone-input-2/lib/material.css";
import Grid from '@mui/material/Grid';
import { useSnackbar } from "../../context/SnackbarContext";
import { Add, AttachFile, Close } from "@mui/icons-material";

const InvestorDetails = () => {
  const { showSnackbar } = useSnackbar();
  const [bankNameList, setBankNameList] = useState([]);
  const [investorTypeList, setInvestorTypeList] = useState([]);
  const [dpNameList, setDpNameList] = useState([]);
  const [investor, setInvestor] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);

  const getUserDetailsList = () => {
    setIsLoading(true);
    getUserDetails()
      .then((res) => {
        setIsLoading(false);
        setInvestor({
          ...res.data,
          readOnlyDobDoi: (res.data?.dobDoi !== null && res.data?.dobDoi !== ""),
          readOnlyInvestorType: res.data?.investorType
        });
      })
      .catch((error) => {
        setIsLoading(false);
        showSnackbar(error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInvestor((prevInvestor) => ({
      ...prevInvestor,
      [name]: value,
    }));
  };

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const invTypes = data.filter(
          (item) => item.ddType === DD_TYPE_INVESTOR_TYPE
        );
        const bankTypes = data.filter((item) =>
          item.ddType === DD_TYPE_BANK_TYPE
        )
        const dpNames = data.filter((item) =>
          item.ddType === DD_TYPE_DP_NAMES
        )
        setInvestorTypeList(invTypes);
        setBankNameList(bankTypes);
        setDpNameList(dpNames);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadDocument = (e) => {
    setIsFileLoading(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setIsFileLoading(false);
        setInvestor(prevState => ({
          ...prevState,
          [e.target.name]: res.data,
        }));
      })
      .catch((err) => {
        setIsFileLoading(false);
        showSnackbar(err);
      });
  }
  const deleteDocument = (key) => {
    setInvestor(prevState => ({
      ...prevState,
      [key]: null,
    }));
  }

  useEffect(() => {
    getUserDetailsList();
    getDataDictionary();
  }, []);

  const saveBasicDetails = () => {
    setIsLoading(true);
    apiSaveBasicDetail({
      fullName: investor?.fullName,
      dobDoi: investor?.dobDoi,
      investorType: investor?.investorType,
      address: investor?.address
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }

  const saveBankDetails = () => {
    setIsLoading(true);
    apiSaveBankInfo({
      // address: investor?.address,
      bankName: investor?.bankName,
      bankIndex: investor?.bankIndex,
      bankAddress: investor?.bankAddress,
      accountNo: investor?.accountNo,
      ifscSwift: investor?.ifscSwift,
      // investorType: investor?.investorType,
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }

  const saveDematDetails = () => {
    setIsLoading(true);
    apiDematInfo({
      dematAccNo: investor?.dematAccNo,
      dpName: investor?.dpName,
      dpId: investor?.dpId,
      dpClientId: investor?.dpClientId,
      firstHolderName: investor?.firstHolderName,
      cmlCopyObject: investor?.cmlCopyObject,
      nsdlCdsl: investor?.nsdlCdsl
    })
      .then((res) => {
        showSnackbar('Data Saved');
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en-gb">
      <Grid container rowSpacing={2} columnSpacing={2}>
        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">BASIC DETAILS</p>
        </Grid>

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Name"
              name="fullName"
              value={investor?.fullName || ""}
              id="fullName"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="fullName">Name</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Inv Code"
              name="invCode"
              value={
                investor?.invCode !== null && investor?.invCode !== ""
                  ? investor?.invCode
                  : ""
              }
              id="invCode"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="invCode">Investor Code</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Phone Number"
              name="number"
              value={
                (investor?.countryCode ? investor?.countryCode : "") +
                (investor?.mobile ? investor?.mobile : "") ?? ""
              }
              id="number"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="number">Phone Number</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Email"
              name="email"
              value={
                investor?.email !== null && investor?.email !== ""
                  ? investor?.email
                  : ""
              }
              id="email"
              onChange={handleInputChange}
              readOnly
            />
            <label htmlFor="email">Email</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="DOB"
              name="dobDoi"
              type="date"
              value={
                investor?.dobDoi !== null && investor?.dobDoi !== ""
                  ? investor?.dobDoi?.split(" ")[0]
                  : ""
              }
              id="dobDoi"
              onChange={handleInputChange}
              readOnly={investor?.readOnlyDobDoi}
            />
            <label htmlFor="dobDoi">DOB</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating mb-3">
            <select
              class="form-select"
              id="investorType"
              name="investorType"
              onChange={handleInputChange}
              value={investor.investorType}
              aria-label="Default select example"
              disabled={investor?.readOnlyInvestorType}
            >
              <option value={null}></option>
              {investorTypeList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
            </select>
            <label htmlFor="investorType">Investor Type</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Address"
              name="address"
              value={
                investor?.address !== null && investor?.address !== ""
                  ? investor?.address
                  : ""
              }
              id="address"
              onChange={handleInputChange}
            />
            <label htmlFor="address">Address</label>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveBasicDetails()}>Save</button>
          </div>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">BANK DETAILS</p>
        </Grid>

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <select
              class="form-select"
              id="bankIndex"
              name="bankIndex"
              onChange={handleInputChange}
              value={investor.bankIndex || null}
              aria-label="Default select example"
            >
              <option value={null}></option>
              {bankNameList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
            </select>
            <label htmlFor="bankIndex">Bank Name</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Bank Address"
              name="bankAddress"
              value={investor?.bankAddress || ""}
              id="bankAddress"
              onChange={handleInputChange}
            />
            <label htmlFor="bankAddress">Bank Address</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Account No"
              name="accountNo"
              value={investor?.accountNo || ""}
              id="accountNo"
              onChange={handleInputChange}
            />
            <label htmlFor="accountNo">Account No</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="IFSC"
              name="ifscSwift"
              value={investor?.ifscSwift || ""}
              id="ifscSwift"
              onChange={handleInputChange}
            />
            <label htmlFor="ifscSwift">IFSC</label>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveBankDetails()}>Save</button>
          </div>
        </Grid>
        
        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <p className="section-label fw-bold p-0 m-0">DEMAT DETAILS</p>
        </Grid>

        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Demat Account Number"
              name="dematAccNo"
              value={investor?.dematAccNo || ""}
              id="dematAccNo"
              onChange={handleInputChange}
            />
            <label htmlFor="dematAccNo">Demat Account Number</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <select
              class="form-select"
              id="dpName"
              name="dpName"
              onChange={handleInputChange}
              value={investor.dpDdIndex || null}
              aria-label="Default select example"
            >
              <option value={null}></option>
              {dpNameList?.map((item) => (
                <option value={item.ddIndex}>{item.ddValue}</option>
              ))}
            </select>
            <label htmlFor="investorType">Depository Participant Name</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="DP ID"
              name="dpId"
              value={investor?.dpId || ""}
              id="dpId"
              onChange={handleInputChange}
            />
            <label htmlFor="dpId">DP ID</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="Client ID"
              name="dpClientId"
              value={investor?.dpClientId || ""}
              id="dpClientId"
              onChange={handleInputChange}
            />
            <label htmlFor="dpClientId">Client ID</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          <div className="form-floating">
            <input
              className="form-control"
              placeholder="First Holder Name"
              name="firstHolderName"
              value={investor?.firstHolderName || ""}
              id="firstHolderName"
              onChange={handleInputChange}
            />
            <label htmlFor="firstHolderName">First Holder Name</label>
          </div>
        </Grid>
        <Grid item xs={6} md={3}>
          {/* <div className="form-floating">
            <input
              className="form-control"
              placeholder="NSDL / CDSL"
              name="nsdlCdsl"
              value={investor?.nsdlCdsl || ""}
              id="nsdlCdsl"
              onChange={handleInputChange}
            />
            <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
          </div> */}
          <div className="form-floating">
            <select
              className="form-select"
              id="nsdlCdsl"
              name="nsdlCdsl"
              onChange={handleInputChange}
              value={investor?.nsdlCdsl || ""}
            >
              <option value=""></option>
              <option value="NSDL">NSDL</option>
              <option value="CDSL">CDSL</option>
            </select>
            <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
          </div>
        </Grid>
        <Grid item xs={6} md={6}>
          <div className="d-flex flex-column">
            <input
              className="d-none"
              id={`input-file`}
              type="file"
              name="cmlCopyObject"
              onChange={uploadDocument}
            />
            <span className="fs-normal-medium">CML COPY</span>

            <div className="d-flex align-items-center">
              {investor?.cmlCopyObject ? (
                <div className="position-relative d-flex gap-2 align-items-center border px-4 py-2 w-100">
                  <div className="position-absolute top-0 end-0 p-1 pointer" onClick={() => deleteDocument('cmlCopyObject')}>
                    <Close />
                  </div>

                  <AttachFile />
                  <span className="fs-normal-med text-break">{investor?.cmlCopyObject?.actualFileName}</span>
                </div>
              ) : (
                <IconButton disabled={isFileLoading || isLoading} onClick={() => document.getElementById(`input-file`).click()}>
                  <Add />
                </IconButton>
              )}
            </div>
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className='d-flex'>
            <button disabled={isLoading} className="btn btn-primary" onClick={() => saveDematDetails()}>Save</button>
          </div>
        </Grid>
      </Grid>
    </LocalizationProvider>
  );
};
export default InvestorDetails;
