import React from "react";
import CardContainer from "../components/Opportunity/CardContainer";
import { STATUS_CLOSED_OPPORTUNITY, STATUS_OPEN_OPPORTUNITY, STATUS_UPCOMING_OPPORTUNITY } from "../../utils/constants/constants";
import { useState, useEffect, useMemo } from "react";
import { apiGetCommitments, getInvestorOpportunity, getOpportunityDetails, getWallet, updateConsent } from "../apis/apis";
import { toLocalePrice } from "../../utils/StringUtil";
import { Fragment } from "react";
import { useHistory } from "react-router-dom";
import { getUserAuthToken } from "../../utils/LocalStorageUtils";
import { Checkbox, Dialog, IconButton, Link } from "@mui/material";
import { BASE_URL } from "../../utils/constants/constants";
import { Button } from "bootstrap";
import { FormControlLabel } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { CheckBox, Close } from "@mui/icons-material";
import DrawDownComponent from "../components/DrawDownComponent";
import { TableHeadButton } from "../../components/ui/TableHeadButton";
// import Dialog from "@mui/material";

function Commitments() {
  const [commitmentList, setCommitmentList] = useState([]);
  const [oppDetail, setOppDetail] = useState(null)
  const [pathToShow, setPathToShow] = useState(null)
  const [selectedCommitment, setSelectedCommitment] = useState(null)
  const [sortColumn, setSortColumn] = useState('date');
  const [sortDirection, setSortDirection] = useState('asc');
  const history = useHistory();

  const getCommitments = () => {
    apiGetCommitments()
      .then((res) => {
        setCommitmentList(res.commitments.reverse());
      }).catch(e => console.error(e))
  }
  const payNow = (ioRid) => {
    history.replace(`/user/commitment-payment?ioRid=${ioRid}&tranType=${process.env.REACT_APP_TYPE_COMMITMENT}`);
  }

  const consentNow = (oppRid, path) => {
    getOpportunityDetails({ oppRid: oppRid })
      .then((res) => {
        setOppDetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  // Function to sort by any column with direction
  const sortByColumn = (tasks, column, direction) => {
    return [...tasks].sort((a, b) => {
      
      // Custom sorting for 'unitAllotted'
      if (column === 'unitAllotted') {
        const aValue = (a.unitAllotted === 1 && a.invOppFiles) ? 2 : a.unitAllotted;
        const bValue = (b.unitAllotted === 1 && b.invOppFiles) ? 2 : b.unitAllotted;
  
        if (aValue < bValue) {
          return direction === 'asc' ? -1 : 1;
        } else if (aValue > bValue) {
          return direction === 'asc' ? 1 : -1;
        } else {
          return 0;
        }
      }
  
      if (column === 'drawdown') {
        const getStatus = task => (task.drawdownFile) ? 'View' : '---';
        const aStatus = getStatus(a);
        const bStatus = getStatus(b);
        if (aStatus === bStatus) {
          return 0;
        }
  
        return direction === 'asc' ? aStatus.localeCompare(bStatus) : bStatus.localeCompare(aStatus);
      }
  
      // Default sorting for other columns
      if (a[column] < b[column]) {
        return direction === 'asc' ? -1 : 1;
      } else if (a[column] > b[column]) {
        return direction === 'asc' ? 1 : -1;
      } else {
        return 0;
      }
    });
  };
  
  const handleSort = (column) => {
    // Determine new sort direction
    const newDirection = sortColumn === column && sortDirection === 'asc' ? 'desc' : 'asc';
    setSortColumn(column);
    setSortDirection(newDirection);
    setCommitmentList(sortByColumn(commitmentList, column, newDirection));
  };

  const handleCloseDialog = () => {
    setPathToShow(null);
  };

  useEffect(() => {
    getCommitments();
  }, []);

  return (
    <div className="card p-4 my-4">
      <table className="table p-0 m-0">
        <tr className="fs-med-semibold text-muted">
          <th scope="col">
            <TableHeadButton
              label="Scheme Name"
              activeUp={sortColumn === 'oppTitle' && sortDirection === 'asc'}
              activeDown={sortColumn === 'oppTitle' && sortDirection === 'desc'}
              onClick={() => handleSort('oppTitle')}
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Status"
              activeUp={sortColumn === 'status' && sortDirection === 'asc'}
              activeDown={sortColumn === 'status' && sortDirection === 'desc'}
              onClick={() => handleSort('status')}
              align="center"
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Drawdown Notice"
              activeUp={sortColumn === 'drawdown' && sortDirection === 'asc'}
              activeDown={sortColumn === 'drawdown' && sortDirection === 'desc'}
              align="center"
              onClick={() => handleSort('drawdown')}
            // leftAlign
            // hideControls
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Consent"
              activeUp={sortColumn === 'consent' && sortDirection === 'asc'}
              activeDown={sortColumn === 'consent' && sortDirection === 'desc'}
              align="center"
              onClick={() => handleSort('consent')}
            // hideControls
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Fund Transfer"
              activeUp={sortColumn === 'fundStatus' && sortDirection === 'asc'}
              activeDown={sortColumn === 'fundStatus' && sortDirection === 'desc'}
              align="center"
              onClick={() => handleSort('fundStatus')}
            // hideControls
            />
          </th>
          <th scope="col">
            <TableHeadButton
              label="Unit Allotment Statement"
              activeUp={sortColumn === 'unitAllotted' && sortDirection === 'asc'}
              activeDown={sortColumn === 'unitAllotted' && sortDirection === 'desc'}
              align="center"
              onClick={() => handleSort('unitAllotted')}
            // hideControls
            />
          </th>
        </tr>
        <tr className="fs-med-semibold bg-light">
          <td >
            <p className="bg-light p-0 m-0">
              {commitmentList.length} Commitments
            </p>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        {commitmentList.length ?
          commitmentList?.map((data, key) => (
            <>
              <tr key={key}>

                <td>
                  <p className=" p-0 m-0">
                    {data.oppTitle}
                  </p>
                </td>
                <td>
                  <p className="p-0 m-0 text-center">
                    <span
                      className={` fs-normal-medium inv-status ${data.oppStatusIndex === 1503 ? "live" : "closing"
                        }`}
                    >
                      {data.oppStatusIndex === 1503 && "Live"}
                      {data.oppStatusIndex === 1502 && "Closing"}
                    </span>
                  </p>
                </td>
                <td>
                  <p className=" p-0 m-0 text-center ">
                    {
                    data.drawdownFile ?
                        <Link color="primary" target="_blank" href={`${BASE_URL}/${JSON.parse(data.drawdownFile).path}`}>View</Link> :'---'
                    }
                  </p>
                </td>
                <td>
                  <p className=" p-0 m-0 text-center">
                    {data.consent === 1 ? <span className="text-success fs-med-semibold">Consented</span> :
                      <button className="btn btn-sm btn-primary" onClick={() => {
                        console.log("DATA",data);
                        setSelectedCommitment({
                          ...data,
                          path: JSON.parse(data.drawdownFile).path
                        })
                        consentNow(data.oppRid)
                        setPathToShow(JSON.parse(data.drawdownFile).path)
                      }
                      }>Consent Now</button>
                    }
                  </p>
                </td>
                <td>
                  <p className=" p-0 m-0 text-center">
                    {data.fundStatus === 1 ? <span className="text-success fs-med-semibold">Received</span> : data.fundStatus === 2 ? <span className="fs-med-semibold text-warning">Pending(Offline)</span> :
                      <button className="btn btn-sm btn-primary" onClick={() => payNow(data.ioRid)}>Pay Now</button>
                    }
                  </p>
                </td>
                <td>
                  <p className=" p-0 m-0 text-center ">
                    {
                    // data.unitAllotted === 0 ? 'Pending' :
                     ( data.unitAllotted === 1 && data.invOppFiles )?
                        <Link color="primary" target="_blank" href={`${BASE_URL}/${JSON.parse(data.invOppFiles).path}`}>View</Link> : 'Pending'
                    }
                  </p>  
                </td>              
                </tr>
            </>
          )) :
          <tr >
            <td colspan="4"><p className="text-center">No Records Found</p>
            </td>
          </tr>
        }
      </table>
      <Dialog open={pathToShow !== null && pathToShow !== undefined} onClose={pathToShow===null} fullScreen>
        <DrawDownComponent 
        isEmailInvestment={false} 
        path={pathToShow} 
        oppDetail={selectedCommitment} 
        token={null} 
        ioRid={selectedCommitment?.ioRid}
        Dialog={true}
        onCancel={handleCloseDialog} />
      </Dialog>
    </div>
  );
}

export default Commitments;
