import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton } from '@mui/material';

function CardLayout({ goHome, children }) {
  const history = useHistory();

  return (
    <div className="art-board py-md-4">
        <div className="container card-flat p-4 p-md-5">
            <div className="d-flex gap-3 align-items-center">
               <IconButton size="large" onClick={()=>history.replace("/user/home")}>
                  <ArrowBackIcon />
                </IconButton>
              
              <img src={"/logo2.svg"} onClick={()=>history.push("/user/home")} alt={"logo"} />
            </div>

            {children}
        </div>
    </div>
  );
}

export default CardLayout;