import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import InvestmentContainer from "../components/InvestmentContainer";
import Graph from "../components/Graph";
import Footer from "../components/Footer";
import { getDashboard, getUserDetails,updateConsent } from "../apis/apis";
import DashboardContent from "../components/DashboardContent";
import { Tab, Tabs } from "@mui/material";
import { Box } from "@mui/system";
import Opportunites from "./Opportunities";
import AllActivity from "./AllActivity";
import { COMPANY_PDP, getDashboardTab, getItem, setDashboardTab, setItem } from "../../utils/LocalStorageUtils";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Commitments from "./Commitments";

const tabStyle = {
  borderBottom: "1px solid rgba(0, 0, 0, 0.175)",
};

function HomePage(props) {
  const history = useHistory();
  const [tabIndex, setTabIndex] = useState(0);
  const [investmentByRounds, setInvestmentByRounds] = useState([]);
  const [cardData, setCardData] = useState(null);

  const [graphData, setGraphData] = useState([]);

  const [showAlertBar, setShowAlertBar] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const handleTab = (value) => {
    setTabIndex(value);
    setDashboardTab(value?.toString())
  };

  const fetchUserData = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { mcaStatus, ckycStatus, investorSip,fullName, email, mobile,countryCode } =userDetails.data;
      localStorage.setItem("fullName", fullName);
      localStorage.setItem("email", email);
      localStorage.setItem("mobileNumber", mobile);
      localStorage.setItem("CountryCode",countryCode)
      if (!(ckycStatus && mcaStatus !== 0)) {
        history.replace("/user/successfull");
      }

      if (!ckycStatus) {
        setAlertMessage("Complete your CKYC process");
      } else if (!mcaStatus !== 0) {
        setAlertMessage("Complete your MCA process");
      } else if (
        investorSip !== null &&
        investorSip?.enachStatus !==
          process.env.REACT_APP_ENACH_STATUS_INPROGRESS
      ) {
        setAlertMessage("Complete your SIP E-Nach process");
      }

      setShowAlertBar(
        !ckycStatus ||
          !mcaStatus ||
          (investorSip !== null &&
            investorSip?.enachStatus !==
              process.env.REACT_APP_ENACH_STATUS_INPROGRESS)
      );
    }
  };

  const getDashboardData = async () => {
    const dashboardData = await getDashboard();

    if (dashboardData.status) {
      const {
        invByRoundList,
        graphList,
        noOfInvestments,
        noOfOpportunity,
        portfolioValue,
        totalInvestedAmount,
        multiple,
      } = dashboardData.data;
      setInvestmentByRounds(invByRoundList);
      setGraphData(graphList);
      setCardData({
        noOfInvestments,
        noOfOpportunity,
        portfolioValue,
        totalInvestedAmount,
        multiple,
      });
    }
  };

  useEffect(() => {
    if(getItem(COMPANY_PDP)){
      history.replace("/user/company-pdp/" + parseInt(getItem(COMPANY_PDP)));
    }

    fetchUserData();
    getDashboardData();

    if(getDashboardTab() !== null){
      if(parseInt(getDashboardTab()) === 0){
        setDashboardTab('0');
      } else {
        setTabIndex(parseInt(getDashboardTab()));
      }
    }
  }, []);

  return (
    <div className="vh-100 d-flex flex-column ">
      {/* <img className="m-4 m-md-5" src={"/logo2.svg"} alt={"logo"} /> */}
      <NavBar />
      <div className="container  px-5 mx-auto flex-grow-1 overflow-auto">
        <Box className="mt-4" style={tabStyle}>
          <Tabs value={tabIndex} onChange={(e, v) => handleTab(v)}>
            <Tab value={0} label="Dashboard" className="fs-med-medium" />
            {/* {localStorage.getItem('Show_Opportunities') && */}
            <Tab value={1} label="Opportunities" className="fs-med-medium" />
            <Tab value={3} label="Commitments" className="fs-med-medium"/>
            <Tab value={2} label="Activity" className="fs-med-medium" />
          </Tabs>
        </Box>

        {tabIndex === 0 && (
          <>
            <Graph cardData={cardData} graphData={graphData} />
            <DashboardContent
              handleTab={handleTab}
              investmentByRounds={investmentByRounds}
            />
            <InvestmentContainer />
          </>
        )}

        {tabIndex === 1 && (
          <>
            <Opportunites />
          </>
        )}

        {tabIndex === 2 && (
          <>
            <AllActivity />
          </>
        )}
        {tabIndex === 3 && (
          <>
            <Commitments />
          </>
        )}


        {/* <CustomTabPanel value={value} index={0}>
        Item One
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        Item Two
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
        {/* {
          showAlertBar ? <div className="alert-bar mt-3 mt-md-4">
            <div className="alert-bar-text" onClick={()=>history.push("/user/profile")}>
              {alertMessage} <ArrowRightAltIcon />
            </div>
          </div> : ''
        } */}
      </div>
      <Footer />
    </div>
  );
}

export default HomePage;
