import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, LinearProgress } from '@mui/material';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import { TRANSACTION_FAILED, TRANSACTION_PENDING, TRANSACTION_SUCCESS } from '../../utils/constants/constants';
import { apiGetRegisteredTransactionDetails, apiSaveTransactionView, apiTransactionDetails } from '../apis/apis';
import { useButtonContext } from '../../context/ButtonContext';
import { setDashboardTab } from '../../utils/LocalStorageUtils';

const PaymentStatus = () => {
    const { buttonText } = useButtonContext()
    const history = useHistory();
    const location = useLocation();

    const { transactionResponse = {}, token } = location.state || {};
    const [requestCount, setRequestCount] = useState(1);
    const [warningMessage, setWarningMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [transactionDetails, setTransactionDetails] = useState(transactionResponse);

    const getTransactionDetails = async () => {
        if(requestCount > 3){
            setWarningMessage('Unable to receive response, you can find the details in the payment history.');
            setIsLoading(false);
        } else {
            try {
                if(token){
                    const data = await apiGetRegisteredTransactionDetails(transactionDetails?.orderId, { token: token });
                    setTransactionDetails(data);
                }
                const data = await apiTransactionDetails(transactionDetails?.orderId);
                setTransactionDetails(data);
            } catch (e) {
                setWarningMessage(e);
                setIsLoading(false);
            }
        }
    }

    const saveViewed = async () => {
        try {
            const data = await apiSaveTransactionView(transactionDetails?.itRid);
        } catch (e) {
            console.log(e);
        }
    }

    useEffect(() => {
        if(transactionDetails?.tranStatus === TRANSACTION_PENDING){
            setRequestCount(requestCount + 1);
            setWarningMessage('Waiting for response...');
            setIsLoading(true);
            
            setTimeout(() => {
                getTransactionDetails();
            }, 5000);
        } else {
            setWarningMessage('');
            setIsLoading(false);
            saveViewed();
        }
    }, [transactionDetails]);

    return (
        <div className='row mx-0 h-100vh auth-wrapper'>
            <div className="col-md"></div>
            <div className="col-md-5 col-12 px-0 border-start border-end d-flex flex-column" style={{ minHeight: '100vh' }}>
                <div className="d-flex justify-content-center  mt-3">
                    <img src={"/logo2.svg"} className='p-3' alt="logo" />
                </div>

                <div className="d-flex flex-column h-100 pt-2 px-5">
                    <div className='row g-0 p-0'>
                        <div className="col-4">
                            {/* <IconButton size="large" onClick={() => history.goBack()}>
                                <ArrowBackIcon />
                            </IconButton> */}
                        </div>

                        <div className="col-4">
                            <div className="d-flex flex-column align-items-center">
                                <span className="fs-large-semibold">Payment Status</span>
                                <div className="fs-title-semibold text-uppercase">
                                    {
                                        transactionDetails?.tranStatus === TRANSACTION_SUCCESS
                                        ? <span className="text-success">Success</span>
                                        : transactionDetails?.tranStatus === TRANSACTION_PENDING
                                        ? <span className="text-warning">Pending</span>
                                        : transactionDetails?.tranStatus === TRANSACTION_FAILED
                                        ? <span className="text-danger">Failed</span>
                                        : ''
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="col-4"></div>
                    </div>

                    {
                        isLoading
                        ? <div className="mt-3">
                            <LinearProgress color="warning" />
                        </div>
                        : ''
                    }

                    { warningMessage === '' ? '' : <div className={`${isLoading ? 'mt-1' : 'mt-3'}`}>
                        <span className="fs-normal-regular text-muted">{warningMessage}</span>
                    </div>}

                    <span className="fs-med-semibold text-primary mt-4">Transaction details</span>

                    <table>
                        <tbody>
                            <tr>
                                <td><span className="fs-med-semibold">Payment mode</span></td>
                                <td className="text-end"><span className="fs-med-regular">Online</span></td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Reference no.</span></td>
                                <td className="text-end"><span className="fs-med-regular">{transactionDetails?.transactionId}</span></td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Order ID</span></td>
                                <td className="text-end"><span className="fs-med-regular">{transactionDetails?.orderId}</span></td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Transaction Amount</span></td>
                                <td className="text-end"><span className="fs-med-regular">{toLocalePrice(transactionDetails?.amt)}</span></td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Purpose of Payment</span></td>
                                <td className="text-end"><span className="fs-med-regular">
                                    {transactionDetails?.tranType === 'WALLET_TOPUP' ? 'Wallet Topup' :
                                     transactionDetails?.tranType === 'COMMITMENT' ? 'Commitment':''}
                                </span></td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Status Description</span></td>
                                <td className="text-end">
                                    {
                                        transactionDetails?.tranStatus === TRANSACTION_FAILED
                                        ? <span className="fs-med-regular text-danger">{transactionDetails?.errorDescription}</span>
                                        : <span className="fs-med-regular">{transactionDetails?.errorDescription}</span>
                                    }
                                </td>
                            </tr>
                            <tr>
                                <td><span className="fs-med-semibold">Date and time</span></td>
                                <td className="text-end"><span className="fs-med-regular">{formatDateTime(transactionDetails?.tranDatetime, true)}</span></td>
                            </tr>
                        </tbody>
                    </table>

                    <div className="d-flex justify-content-center mt-4">
                        {transactionDetails.tranType === "WALLET_TOPUP" ?
                                <button className="btn btn-primary" onClick={() => { history.push("/user/home"); setDashboardTab(0) }} disabled={isLoading}>Done</button>
                                : transactionDetails.tranType === "COMMITMENT" ?
                                    <button className="btn btn-primary" onClick={() => { history.push("/user/home"); setDashboardTab(1) }} disabled={isLoading}>Explore Other Opportunities</button> :
                                    <button className="btn btn-primary" onClick={() => { history.push("/user/home"); setDashboardTab(0) }} disabled={isLoading}>{buttonText}</button>
                        }
                    </div>
                </div>
                {/* <div className='p-5'>
                    <Footer />
                </div> */}
            </div>
            <div className="col-md"></div>
        </div>
    );
}

export default PaymentStatus;