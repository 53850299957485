import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { apiTransactionDetails, getWallet, apiGetCommitmentDetails, apiGetConvenienceFee, apiCommitmentTransaction, apiGetSchemeEmailerConvenienceFee, getWalletSchemeEmailer, apiGetRegisteredCommitmentDetails, apiCommitmentTransactionSchemeEmailer, apiGetRegisteredTransactionDetails } from "../apis/apis";
import { formattedCurrency } from "../../utils/userUtilities";
import CardLayout from "../components/layout/CardLayout";
import CustomSnackbar from "../components/Toast/CustomSnackbar";
import { FormControl, FormGroup, FormControlLabel, Checkbox, Box } from "@mui/material";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { useSnackbar } from "../../context/SnackbarContext";

function PaymentGatewayPage() {

    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const tranType = searchParams.get('tranType');
    const ioRid = searchParams.get('ioRid');
    const isEmailInvestment = searchParams.get('isEmailInvestment');
    const token = searchParams.get('token')
    const [walletAmount, setWalletAmount] = useState(0);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [toastStatus, setToastStatus] = useState("error");
    const [paymentType, setPaymentType] = useState('');
    const [commitmentDetail, setCommitmentDetail] = useState(null)
    const [walletOption, setWalletOption] = useState(false);
    const [payOffline, setPayOffline] = useState(false);
    const [convenienceFee, setConvenienceFee] = useState(0);
    const [showSuccess, setShowSuccess] = useState(false)
    let history = useHistory()
    const { showSnackbar } = useSnackbar();

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const handleWalletOption = (e) => {
        setWalletOption(e.target.checked);
        setPayOffline(false);
    };

    const handlePayOffline = (e) => {
        setPayOffline(e.target.checked);
        setWalletOption(false);
    };

    const saveOffline = () => {
        setIsLoading(true);
        if(isEmailInvestment){
            apiCommitmentTransactionSchemeEmailer(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline }, { token: token })
            .then((res) => {
                if (res.status === "SUCCESS") {
                    setShowSuccess(true);
                } else if (res.status === "INITIATED") {
                    makePayment(res.data);
                }
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }else{
            apiCommitmentTransaction(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline })
            .then((res) => {
                if (res.status === "SUCCESS") {
                    setShowSuccess(true);
                }
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }
        
    }
    const getConvenienceFee = () => {
        apiGetConvenienceFee()
            .then((data) => {
                setConvenienceFee(data.convenienceFee);
            })
    }

    const getSchemeEmailConvenienceFee = () => {
        apiGetSchemeEmailerConvenienceFee()
            .then((data) => {
                setConvenienceFee(data.convenienceFee);
            })
    }

    const getSchemeEmailWalletData = async () => {
        const walletData = await getWalletSchemeEmailer({ "token": token });
        // setIsLoading(false)
        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }
    const getCommitmentDetails = () => {
        apiGetCommitmentDetails(ioRid)
            .then((res) => {
                setCommitmentDetail(res);
                localStorage.setItem("email", res.email);
            })
            .catch((err) => {
                console.log(err);
            });
    }

    const getSchemeEmailCommitmentDetails = () => {
        apiGetRegisteredCommitmentDetails(ioRid, token)
            .then((res) => {
                setCommitmentDetail(res);
            })
            .catch(e => showSnackbar(e))
    }

    const getWalletData = async () => {
        const walletData = await getWallet();

        if (walletData.status) {
            const balanceAmt = walletData?.data?.balanceAmt ?? 0;
            setWalletAmount(balanceAmt);
        }
    }

    const makePayment = (data) => {
        var flow_config = {
            merchantId: data.merchantId,
            bdOrderId: data.bdorderId,
            authToken: data.token,
            childWindow: true,
            returnUrl: '',
            crossButtonHandling: 'Y',
            retryCount: 0
        };

        var responseHandler = function (txn) {
            if (txn.txnResponse) {
                setIsLoading(true);
                getTransactionDetails(data.orderId);
            } else {
                setErrorMessage("Transaction Not Completed");
                setIsLoading(false);
                setToastStatus("error");
                setOpenSnackbar(true);
            }
        };

        var config = {
            responseHandler: responseHandler,
            flowConfig: flow_config,
            flowType: "payments"
        };
        window.loadBillDeskSdk(config);
    }

    const handleCreateCommitTransaction = (ioRid) => {
        setIsLoading(true);
        if(isEmailInvestment){
            apiCommitmentTransactionSchemeEmailer(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline }, { token: token })
            .then((res) => {
                if (res.status === "SUCCESS") {
                    setShowSuccess(true);
                } else if (res.status === "INITIATED") {
                    makePayment(res.data);
                }
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }else{
            apiCommitmentTransaction(ioRid, { "includeWalletBalance": walletOption, "payOffline": payOffline })
            .then((res) => {
                console.log(ioRid)
                if (res.status === "SUCCESS") {
                    setShowSuccess(true);
                } else if (res.status === "INITIATED") {
                    makePayment(res.data);
                }
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }
        
    }

    const getTransactionDetails = (orderid) => {
        setIsLoading(true);
        if(isEmailInvestment){
            apiGetRegisteredTransactionDetails(orderid,token)
            .then((res) => {
                setIsLoading(false);
                setTimeout(() => {
                    history.replace({
                        pathname: "/user/payment-status",
                        state: { transactionResponse: res, token:token }
                    });
                }, 1000);
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }else{
            apiTransactionDetails(orderid)
            .then((res) => {
                setIsLoading(false);
                setTimeout(() => {
                    history.replace({
                        pathname: "/user/payment-status",
                        state: { transactionResponse: res}
                    });
                }, 1000);
            }).catch((err) => {
                setIsLoading(false);
                setErrorMessage(err.toString());
                setToastStatus("error");
                setOpenSnackbar(true);
            });
        }
       
    }
    const handleProceed = () => {
        handleCreateCommitTransaction(ioRid);
    }

    useEffect(() => {
        if (tranType === process.env.REACT_APP_TYPE_COMMITMENT) {
            setPaymentType('Commitment Payment');
        }
        if (isEmailInvestment) {
            getSchemeEmailWalletData();
            getSchemeEmailConvenienceFee();
            getSchemeEmailCommitmentDetails();
        }else{
            getCommitmentDetails(ioRid);
            getConvenienceFee();
            getWalletData();
        }
        
    }, [])

    if (!commitmentDetail) {
        return (<div className="loading-page">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>)
    }

    if (showSuccess)
        return (
            <>
                <div >
                    <NavBar />
                    <div className="container mt-3">
                        <p className="fs-title-lg-semibold fw-bold text-center mb-2">{commitmentDetail.oppTitle}</p>
                        <p className="fs-title-semibold text-center">Thank you for your Investment</p>
                        {payOffline ?
                            <div className="d-flex justify-content-center">

                                <div className="d-flex flex-row justify-content-center border rounded mx-auto w-50 p-4">
                                    <table>
                                        <tr>
                                            <td>
                                                <p className="fs-lg-bold text-center p-0 m-0"><b>The wiring instructions are as follows:</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">Name</p></td>
                                            <td><p className="p-0 m-0">: SALIL CHAKRABARTY INNOVATION FUND</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">Bank</p></td>
                                            <td><p className="p-0 m-0">: RBL BANK</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">For credit to A/c No</p></td>
                                            <td><p className="p-0 m-0">: 409001821513</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">IFSC</p></td>
                                            <td><p className="p-0 m-0">: RATN0000116</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">SWIFT</p></td>
                                            <td><p className="p-0 m-0">: RATNINBB</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">TYPE</p></td>
                                            <td><p className="p-0 m-0">: Current Account</p></td>
                                        </tr>
                                    </table>
                                </div>

                            </div> : null}
                        <div className="mt-2">
                            <p className="fs-med-semibold text-center">
                                You will receive an email once the funds are deployed
                            </p>
                        </div>
                        <div className="row justify-content-center">
                            <button onClick={() => history.replace("/user/home")} className="btn btn-primary w-auto px-4">
                                Explore Other Opportunities
                            </button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )
    return (
        <CardLayout>
            <div className="container mt-4">
                <p className="fs-title-lg-semibold fw-bold text-center mb-4">{commitmentDetail.oppTitle}</p>

                <div className="border rounded mx-auto w-75 p-4 mb-5">


                    <Box className='mt-3' sx={{ padding: '5px' }}>
                        {walletAmount > 0 ? <div className='d-flex flex-row gap-3 justify-content-center'>
                            <p className='fs-large-semibold'>Wallet Balance</p>
                            <p className='fs-large-semibold'>: {formattedCurrency(walletAmount)}</p>
                        </div> : null}

                        <FormControl component="fieldset" fullWidth>
                            <Box display="flex" justifyContent="center">
                                <FormGroup>
                                    {walletAmount > 0 ? <b>
                                        <FormControlLabel
                                            control={<Checkbox checked={walletOption} onChange={handleWalletOption} />}
                                            label="Use Wallet Balance"
                                            labelPlacement="end"
                                        />
                                    </b> : null}

                                    <b>
                                        <FormControlLabel
                                            control={<Checkbox checked={payOffline} onChange={handlePayOffline} />}
                                            label="Pay Offline"
                                            labelPlacement="end"
                                        />
                                    </b>
                                </FormGroup>
                            </Box>
                        </FormControl>
                    </Box>
                </div>

                {/* Payment Summary Table */}
                <div className="border rounded mx-auto w-75 p-4 mb-5">

                    <h3 className="fs-title-semibold text-secondary text-center p-0 m-0 mb-2">
                        Payment Summary
                    </h3>
                    <div className="d-flex justify-content-center flex-column mx-auto col-8">
                        <table className="table table-bordered ">
                            <tbody>
                                <tr>
                                    <td><p className="fs-large-semibold">Commitment</p></td>
                                    <td><p className="fs-5 text-end p-0 m-0"><b> {formattedCurrency(commitmentDetail.commitment)}</b> </p></td>
                                </tr>
                                <tr>
                                    <td><p className="fs-large-semibold">Upfront Fees</p></td>
                                    <td><p className="fs-5 text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.upfrontAmount)}</b> </p></td>
                                </tr>
                                <tr>
                                    <td><p className="fs-large-semibold">Statutory Fees</p></td>
                                    <td><p className="fs-5 text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.gst)}</b> </p></td>
                                </tr>


                                {commitmentDetail.dematCharges ?
                                    <tr>
                                        <td><p className="fs-large-semibold">Demat Charges</p></td>
                                        <td><p className="fs-5 text-end p-0 m-0"><b> + {formattedCurrency(commitmentDetail.dematCharges)}</b> </p></td>
                                    </tr> : null
                                }


                                {!payOffline && !walletOption || (walletOption && walletAmount < commitmentDetail.totalAmount) ?
                                    <tr>
                                        <td><p className="fs-large-semibold">Convenience Fees</p></td>
                                        <td><p className="fs-5 text-end p-0 m-0"><b>+ {formattedCurrency(convenienceFee)} </b></p></td>
                                    </tr> : null
                                }
                                <tr>
                                    <td><p className="fs-large-semibold">Total Amount</p></td>
                                    {payOffline ?
                                        <td><p className="fs-5 text-end p-0 m-0"><b>
                                            {formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td> :

                                        <td><p className="fs-5 text-end p-0 m-0"><b> {!walletOption || (walletOption && walletAmount < commitmentDetail.totalAmount) ?
                                            formattedCurrency(parseFloat(commitmentDetail.totalAmount) + parseFloat(convenienceFee)) :
                                            formattedCurrency(parseFloat(commitmentDetail.totalAmount))}</b></p></td>
                                    }

                                </tr>
                                {walletAmount > 0 && walletOption ? (
                                    <tr>
                                        <td><p className="fs-large-semibold">Wallet Balance Applied :</p></td>
                                        <td><p className="fs-5 text-end p-0 m-0">
                                            {walletAmount <= commitmentDetail.totalAmount ? (
                                                <b>- {formattedCurrency(walletAmount)}</b>
                                            ) : (

                                                <b>- {formattedCurrency(commitmentDetail.totalAmount)}</b>
                                            )}
                                        </p></td>
                                    </tr>
                                ) : null}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="border rounded mx-auto w-75 p-4 mb-4">
                    <h3 className="fs-2 text-secondary text-center p-0 m-0">
                        Amount Payable:
                        {!walletOption && !payOffline ? (
                            <b>{formattedCurrency(
                                parseFloat(commitmentDetail.totalAmount) +
                                parseFloat(convenienceFee)
                            )}</b>
                        ) : payOffline ? (
                            <b>{formattedCurrency(
                                parseFloat(commitmentDetail.totalAmount)
                            )}</b>
                        ) : (
                            walletOption && walletAmount >= commitmentDetail.totalAmount ? (
                                <b>{formattedCurrency(
                                    parseFloat(0)
                                )}</b>
                            ) : (
                                <b>{formattedCurrency(
                                    (parseFloat(commitmentDetail.totalAmount) +
                                        parseFloat(convenienceFee)) -
                                    walletAmount
                                )}</b>
                            )
                        )}
                    </h3>
                </div>
            </div>

            <div className="d-flex justify-content-center mb-5">
                {!payOffline ? <button
                    className="btn btn-primary px-4 py-2 mb-5"
                    onClick={handleProceed}
                    disabled={isLoading}
                >
                    {isLoading ? "Loading..." : walletOption && walletAmount >= commitmentDetail.totalAmount ? "CONFIRM" : "PAY NOW"}
                </button> :
                    <button
                        className="btn btn-primary px-4 py-2 mb-5"
                        onClick={saveOffline}
                        disabled={isLoading}
                    >
                        {isLoading ? "Loading..." : "PAY OFFLINE"}
                    </button>}

            </div>

            <CustomSnackbar
                open={openSnackbar}
                severity={toastStatus}
                duration={4000}
                text={errorMessage}
                handleClose={handleSnackbarClose}
            />
        </CardLayout>

    );
}

export default PaymentGatewayPage;