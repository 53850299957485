import React, { useEffect, useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import { getOpportunityDetails, getUserDetails } from "../apis/apis";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import AspectRatioOutlinedIcon from '@mui/icons-material/AspectRatioOutlined';
import { toCompactPricePrefix } from "../../utils/StringUtil";
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import CompanyPdpModal from "../components/CompanyPdpModal";
import { STATUS_OPEN_OPPORTUNITY } from "../../utils/constants/constants";
import { COMPANY_PDP, LSK_MCA_STATUS, getItem, setItem } from "../../utils/LocalStorageUtils";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CoInvestor from "../components/CoInvestor";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import { useButtonContext } from "../../context/ButtonContext";

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function CompanyPDP() {
  const { setButtonText } = useButtonContext()

  const handleClick = () => {
    setButtonText('Explore Other Opportunities')
  }
  const { oppRid } = useParams();
  const [opportunityDetails, setOpportunityDetails] = useState({});

  const [activeTab, setActiveTab] = useState(0);
  const handleTabAction = (index) => {
    setActiveTab(index);
  };
  const tabItems = [
    { 'id': 0, 'title': 'Details of Offer', 'key': 'offerDetails' },
    { 'id': 1, 'title': 'Previous Round', 'key': 'pastRounds' },
    { 'id': 2, 'title': 'Due Diligence', 'key': 'dueDilligence' },
    { 'id': 3, 'title': 'Documents', 'key': '' },
  ]

  const [mcaStatus, setMcaStatus] = useState(0)

  const SUMMARY_DOCUMENTS = 3

  const history = useHistory();

  const [oppDecModalState, setOppDecModalState] = useState(false)

  const [lumpsumModal, setLumpsumModal] = useState(false);
  const [sipModal, setSipModal] = useState(false);

  const handleLumpsum = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { mcaStatus, ckycStatus } = userDetails.data;

      if (mcaStatus !== 0 && ckycStatus) {
        // history.push("/user/lumpsum-io/" + oppRid);
        history.push("/user/contact-team");
      } else {
        setLumpsumModal(true);
      }
    }
  };
  const handleLumpsumModalClose = () => {
    setLumpsumModal(false);
  };

  const handleInvestSIP = async () => {
    const userDetails = await getUserDetails();

    if (userDetails.status) {
      const { mcaStatus, ckycStatus, investorSip, invPref } = userDetails.data;

      if (mcaStatus !== 0 && ckycStatus) {
        // if(investorSip === null){
        //   history.push("/user/invest-sip-method/");
        // } else {
        //   if(investorSip.enachStatus !== process.env.REACT_APP_ENACH_STATUS_INPROGRESS){
        //     setSipModal(true);
        //   } else {
        //     history.push("/user/invest-sip/" + oppRid);
        //   }
        // }
        history.push("/user/contact-team");
      } else {
        setSipModal(true);
      }
    }
  };
  const handleSipModalClose = () => {
    setSipModal(false);
  };

  useEffect(() => {
    setItem(COMPANY_PDP, "");
    setMcaStatus(+getItem(LSK_MCA_STATUS))
    if (oppRid)
      getOpportunityDetails({ oppRid: oppRid })
        .then((data) => {
          setOpportunityDetails(data.data);
        })
        .catch((err) => {
          console.log(err);
        });
  }, []);


  const onBackClick = () => {
    history.goBack()
  }


  return (
    <div>
      <NavBar />
      <div className="container pt-2">
        <KeyboardBackspaceIcon onClick={onBackClick} className='text-dark mt-4 pointer' />
        <h1 className="fs-title-lg-semibold mt-3 mb-0">
          {opportunityDetails.oppTitle}
        </h1>
        <div className="row mx-0 border rounded mt-5 p-4 align-items-center">
          <img
            className="company-pdp-banner d-block p-0"
            src={
              opportunityDetails.bannerImgPath ?
                process.env.REACT_APP_BASE_URL + "/" + opportunityDetails.bannerImgPath :
                process.env.PUBLIC_URL + "/img/placeholder.png"
            }
            onError={({ currentTarget }) => {
              currentTarget.onerror = null;
              currentTarget.src = process.env.PUBLIC_URL + "/img/placeholder.png";
            }}
            alt="company-pic"
          />
          <div className="col ps-md-4 ps-0">
            <div className="row mx-0 mt-md-0 mt-4">
              <div className="col-6 ps-0 ps-md-3 mb-4">
                <p className='mb-0'>
                  Round Size
                </p>
                <p className='fs-4 mb-0'>
                  {toCompactPricePrefix(opportunityDetails.roundSize)}
                </p>
              </div>
              <div className="col-6 ps-0 ps-md-3 mb-4">
                <p className='mb-0'>
                  Domain
                </p>
                <p className='fs-4 mb-0'>
                  {opportunityDetails.domain}
                </p>
              </div>
              <div className="col-6 ps-0 ps-md-3 mb-4">
                <p className='mb-0'>
                  Type of Investment
                </p>
                <p className='fs-4 mb-0'>
                  {opportunityDetails.typeOfInv}
                </p>
              </div>
              <div className="col-6 ps-0 ps-md-3 mb-4">
                <p className='mb-0'>
                  Round
                </p>
                <p className='fs-4 mb-0'>
                  {opportunityDetails.invRound}
                </p>
              </div>
              <div className="col-6 ps-0 ps-md-3 mb-4">
                <p className='mb-0'>
                  Valuation
                </p>
                <p className='fs-4 mb-0'>
                  {toCompactPricePrefix(opportunityDetails.valuation)}
                </p>
              </div>
            </div>
          </div>
        </div>

        {
          opportunityDetails?.coInvestorsList?.length > 0 &&
          <div className="border d-flex flex-column align-items-center rounded p-4 mt-5">
            <p className='fs-4 mb-0 fw-bold'>Prominent Co-Investers</p>
            <CoInvestor coInvestorsList={opportunityDetails.coInvestorsList} />
          </div>
        }

        <div className="position-relative border rounded p-4 mt-5">
          <div className="d-flex justify-content-between">
            <p className='fs-4 mb-0 text-center fw-bold mb-4'>Scheme Description</p>
            <AspectRatioOutlinedIcon className="pointer" onClick={e => setOppDecModalState(true)} />
          </div>
          <div className="opp-desc-wrapper" dangerouslySetInnerHTML={{ __html: opportunityDetails.oppDesc }} />
          <div className="position-absolute bottom-0 start-50 translate-middle-x p-3">
            <div className="d-flex gap-1 align-items-center">
              <div className="animate-scroll-down">
                <KeyboardDoubleArrowDownIcon />
              </div>
              <span className="fs-med-medium">Scroll</span>
            </div>
          </div>
        </div>

        <CompanyPdpModal title={opportunityDetails.oppTitle} open={oppDecModalState} onClose={setOppDecModalState} description={opportunityDetails.oppDesc} />

        <div className="d-flex align-items-center flex-column sprint-thesis-wrapper pb-4 mt-5">
          <p className='fs-4 fw-bold'>Sprint Thesis</p>
          {/* <h6 className="fs-6 fw-bold mb-3">
            Why the GPS are investing into this company
          </h6> */}
          <div className="row w-100">
            <div id="sprintThesis" className="carousel slide pb-3">
              <div className="carousel-indicators">
                {
                  opportunityDetails.thesis?.map((data, index) => {
                    return (
                      <button key={index} type="button" className={`${index === 0 ? 'active' : ''}`} data-bs-target="#sprintThesis" data-bs-slide-to={index} aria-current="true" aria-label={`Slide ${index}`}></button>
                    );
                  })
                }
              </div>
              <div className="carousel-inner">
                {
                  opportunityDetails.thesis?.map((data, index) => {
                    return (
                      <div key={index} className={`carousel-item pb-4 ${index === 0 ? 'active' : ''}`}>
                        <div className="row justify-content-center">
                          <div className="col-md-10 col-12">
                            <p className="fs-6 text-center">{data}</p>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
        </div>

        <div className="border rounded p-4 mb-5">
          <div className="row w-100 mx-0">
            <div className="col-12 px-0">
              <p className='fs-4 mb-0 text-center fw-bold'>Summary</p>
              <div className="d-flex border-bottom summary-tab-wrapper justify-content-md-center mt-4 mx-0" >
                {
                  tabItems.map((data, key) => {
                    return (
                      <p
                        key={key}
                        className={`bg-white px-4 py-2 text-dark w-auto pointer mb-0 fw-bold c-pdp-summary-tab ${activeTab === data.id ? 'c-pdp-summary-tab-border' : ''}`}
                        onClick={() => handleTabAction(data.id)}
                      >
                        {data.title}
                      </p>

                    );
                  })
                }
              </div>
            </div>
            <div className="col-12 p-md-4">
              {
                SUMMARY_DOCUMENTS === activeTab ?
                  (
                    <div className="d-flex justify-content-center">
                      {
                        opportunityDetails.oppFiles.map((file, fileIndex) => (
                          <div key={fileIndex} className="summary-doc d-flex flex-column justify-content-center align-items-center border rounded p-3 me-2">
                            <InsertDriveFileOutlinedIcon className="icon" />
                            <p className="fs-small-regular mb-0 mt-1">
                              {file.actualFileName}
                            </p>
                          </div>
                        ))
                      }
                    </div>
                  )
                  : (<p className="mb-0 text-center fs-6">{opportunityDetails[tabItems[activeTab].key]}</p>)
              }
            </div>
          </div>
        </div>

        {!opportunityDetails.isInvested && opportunityDetails.oppStatusIndex === STATUS_OPEN_OPPORTUNITY && mcaStatus === 1 && <div className="row justify-content-center">
          <button
            onClick={e => {
              handleClick(); 
              history.push(`/user/invest-now/${opportunityDetails.oppRid}`, { fromExplore: true });
            }}
            className="btn btn-primary w-auto px-4"
          >
            Commit Now
          </button>
        </div>}

        {/* <div className={'row g-4 ' + (opportunityDetails.oppStatusIndex !== 1502 ? 'd-none' : '')}>
          <div className="col-12 col-md-6">
            <div className="card-raised h-100 p-5">
              <div className="d-flex align-items-center flex-column">
                <h2 className="card-title-md text-center mb-4">Invest Now</h2>
                <p className="card-text text-center px-5 mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <h5 className="fw-bold text-center">SELECT INVESTMENT PLAN</h5>
                <p className="card-text text-center mb-5">You can select both</p>
                <button className="color-btn" onClick={handleLumpsum}>
                  LUMPSUM
                </button>
                <Modal
                  open={lumpsumModal}
                  onClose={handleLumpsumModalClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...modalStyle, width: 400 }}>
                    <h2 id="parent-modal-title">Complete your profile</h2>
                    <p id="parent-modal-description">
                      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </p>
                    <button className="color-btn mt-4" onClick={() => history.push("/user/profile/")}>Proceed</button>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card-raised h-100 p-5">
              <div className="d-flex align-items-center flex-column">
                <h2 className="card-title-md text-center mb-4">Invest through Angel</h2>
                <p className="card-text text-center px-5 mb-5">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                <h5 className="fw-bold text-center">SELECT INVESTMENT PLAN</h5>
                <p className="card-text text-center mb-5">You can select both</p>
                <button className="color-btn" onClick={handleInvestSIP}>
                  INVEST SIP
                </button>
                <Modal
                  open={sipModal}
                  onClose={handleSipModalClose}
                  aria-labelledby="parent-modal-title"
                  aria-describedby="parent-modal-description"
                >
                  <Box sx={{ ...modalStyle, width: 400 }}>
                    <h2 id="parent-modal-title">Complete your profile</h2>
                    <p id="parent-modal-description">
                      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
                    </p>
                    <button className="color-btn mt-4" onClick={() => history.push("/user/profile/")}>Proceed</button>
                  </Box>
                </Modal>
              </div>
            </div>
          </div>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
