import React, { useEffect, useState } from "react";
import AuthLayout from "../../components/layout/AuthLayout";
import { useHistory } from "react-router-dom";
import { apiDematInfo, apiNotifyAdmin, getDataDictionaryValues, getUserDetails, uploadFile } from "../../apis/apis";
import { useSnackbar } from "../../../context/SnackbarContext";
import { DD_TYPE_DP_NAMES } from "../../../utils/constants/constants";
import { AttachFile, Close } from "@mui/icons-material";
import { setDashboardTab } from "../../../utils/LocalStorageUtils";

const DematDetails = () => {
  let history = useHistory();
  const { showSnackbar } = useSnackbar();
  const [bankNameList, setBankNameList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [dpNameList, setDpNameList] = useState([]);
  const [data, setData] = useState({
    dematAccNo: "",
    dpName: "",
    dpId: "",
    dpClientId: "",
    firstHolderName: "",
    cmlCopyObject: null,
    nsdlCdsl: ""
  });

  const handle = (e) => {
    const newData = { ...data };
    newData[e.target.name] = e.target.value;
    setData(newData);
  };

  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    apiDematInfo(data)
      .then(() => {
        history.push("/user/agreement")
      })
      .catch((err) => {
        showSnackbar(err);
      })
      .finally(() => setIsLoading(false));
  };

  const fetchDematData = async () => {
    const userDetails = await getUserDetails();
    if (userDetails.status) {
      const {
        dematAccNo,
        dpName,
        dpId,
        dpClientId,
        firstHolderName,
        cmlCopyObject,
        nsdlCdsl
      } = userDetails.data;
      setData({
        dematAccNo,
        dpName,
        dpId,
        dpClientId,
        firstHolderName,
        cmlCopyObject,
        nsdlCdsl
      });
    }
  };

  const getDataDictionary = () => {
    getDataDictionaryValues()
      .then((data) => {
        const dpNames = data.filter((item) =>
          item.ddType === DD_TYPE_DP_NAMES
        )

        setDpNameList(dpNames);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const uploadDocument = (e) => {
    setIsFileLoading(true);
    uploadFile(e.target.files[0])
      .then((res) => {
        setIsFileLoading(false);
        setData(prevState => ({
          ...prevState,
          [e.target.name]: res.data,
        }));
      })
      .catch((err) => {
        setIsFileLoading(false);
        showSnackbar(err);
      });
  }
  const deleteDocument = (key) => {
    setData(prevState => ({
      ...prevState,
      [key]: null,
    }));
  }

  useEffect(() => {
    fetchDematData();
    getDataDictionary();
  }, []);

  const notifyAdmin = () => {
    apiNotifyAdmin({
      contextType : 3
    })
    .then(() => {
      showSnackbar('Success');
      setTimeout(() => {
        history.replace("/user/contact-team");
      }, 2000);
    })
    .catch((err) => {
      showSnackbar(err);
    });
  }

  return (
    <AuthLayout title="Demat Details">
      <form onSubmit={submit}>
        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="dematAccNo"
            name="dematAccNo"
            placeholder="Demat Account Number"
            value={data.dematAccNo}
            onChange={handle}
            maxLength={16}
          />
          <label htmlFor="dematAccNo">Demat Account Number</label>
        </div>

        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="dpDdIndex"
            name="dpDdIndex"
            onChange={handle}
            value={data.dpDdIndex}
          >
            <option value="">---</option>
            {
              dpNameList.map((item, key) => (
                <option value={item?.ddIndex} key={key}>{item?.ddValue}</option>
              ))
            }
          </select>
          <label htmlFor="dpDdIndex">Depository Participant (DP) Name</label>
        </div>

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="dpId"
            name="dpId"
            placeholder="DP ID"
            value={data.dpId}
            onChange={handle}
            inputProps={{ maxLength: 8 }}
          />
          <label htmlFor="dpId">DP ID</label>
        </div>

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="dpClientId"
            name="dpClientId"
            placeholder="Client ID"
            value={data.dpClientId}
            onChange={handle}
          />
          <label htmlFor="dpClientId">Client ID</label>
        </div>

        <div className="form-floating mb-3">
          <input
            className="form-control"
            type="text"
            id="firstHolderName"
            name="firstHolderName"
            placeholder="First Holder Name"
            value={data.firstHolderName}
            onChange={handle}
          />
          <label htmlFor="firstHolderName">First Holder Name</label>
          <div className="form-text">
            Must be same as Investor name
          </div>
        </div>

        <div className="d-flex gap-2 flex-column mb-3">
          <input
            className="d-none"
            id={`input-file`}
            type="file"
            name="cmlCopyObject"
            onChange={uploadDocument}
          />
          <span className="fs-med-medium">CML COPY</span>
          <div className="d-flex align-items-center">
            {data.cmlCopyObject ? (
              <div className="position-relative d-flex gap-2 justify-content-center align-items-center flex-column border p-4 w-100">
                <div className="position-absolute top-0 end-0 p-1 pointer" onClick={() => deleteDocument('cmlCopyObject')}>
                  <Close />
                </div>

                <AttachFile />
                <span className="fs-normal-med">{data.cmlCopyObject?.actualFileName}</span>
              </div>
            ) : (
              <button type="button" disabled={isFileLoading || isLoading} className="btn btn-secondary" onClick={() => document.getElementById(`input-file`).click()}>
                Add File
              </button>
            )}
          </div>
        </div>

        <div className="form-floating mb-3">
          <select
            className="form-select"
            id="nsdlCdsl"
            name="nsdlCdsl"
            onChange={handle}
            value={data.nsdlCdsl}
          >
            <option value="">---</option>
            <option value="NSDL">NSDL</option>
            <option value="CDSL">CDSL</option>
          </select>
          <label htmlFor="nsdlCdsl">NSDL / CDSL</label>
        </div>

        <div className="form-floating mb-3">
          
        </div>

        <div className="d-flex mt-3">
          <button type="submit" disabled={isLoading} className="btn btn-primary px-3 py-2">
            Save & Continue
          </button>
          <button type="button" className="btn btn-light px-3 ms-3 py-2" 
          onClick={() =>{
            setDashboardTab(1);
            history.push("/user/home")
          }
             }>
            I'll Fill This Later
          </button>
        </div>

        <p className="p-0 mt-3">If you need assistance, <span className='text-primary custom-link' onClick={() => notifyAdmin()}>Admin Help</span></p>
      </form>
    </AuthLayout>
  );
};

export default DematDetails;
