import React, { useEffect, useState } from 'react'
import { apiCommitment, apiDraftDrawdownFile, apiGetConvenienceFee, apiGetCommitmentDetails, getWalletSchemeEmailer, apiCommitmentTransactionSchemeEmailer, apiGetSchemeEmailerConvenienceFee, apiGetRegisteredCommitmentDetails, apiCommitmentTransaction, apiTransactionDetails, apiSaveConsent, apiSaveNonRegisteredCommitment, apiSaveRegisteredCommitment, apiSaveDraftConsent, apiSaveSchemeEmailerConsent, apiSaveDraftEmailInvestment, getOpportunityDetails, getWallet, saveInvestment, updateConsent, apiGetRegisteredTransactionDetails } from '../apis/apis';
import { formattedCurrency, formattedoCurrencyNumbering } from '../../utils/userUtilities';
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { toCompactPrice } from '../../utils/StringUtil';
import { useSnackbar } from '../../context/SnackbarContext';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import { BASE_URL } from '../../utils/constants/constants';
import { Box, FormControl, Typography, FormGroup, Checkbox, FormControlLabel, Dialog, DialogContent, DialogContentText, DialogTitle, Button, DialogActions, Divider } from '@mui/material';
import { getUserAuth, setDashboardTab } from '../../utils/LocalStorageUtils';
import ReportIcon from '@mui/icons-material/Report';
import CustomSnackbar from '../components/Toast/CustomSnackbar';
import CardLayout from "../components/layout/CardLayout";
import { TRANSACTION_FAILED, TRANSACTION_PENDING, TRANSACTION_SUCCESS } from '../../utils/constants/constants';
import { IconButton, LinearProgress } from '@mui/material';
import { formatDateTime, toLocalePrice } from '../../utils/StringUtil';
import DrawDownComponent from '../components/DrawDownComponent';


const InvestNow = () => {

    const { oppRid } = useParams()
    let history = useHistory()
    const location = useLocation();
    const { showSnackbar } = useSnackbar()

    const [isLoading, setIsLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [investmentAmount, setinvestmentAmount] = useState(0)
    const [oppDetail, setOppDetail] = useState(null)
    const [investmentError, setInvestmentError] = useState(null)
    const [showDrawdown, setShowDrawdown] = useState(false)
    const [showSuccess, setShowSuccess] = useState(false)
    const [drawdownData, setDrawdownData] = useState(null)
    const { isEmailInvestment, minAmount, token, iid } = location.state || {};
    const [showContactTeam, setShowContactTeam] = useState(false);
    const [ioRid, setIoRid] = useState(0);
    const [showSuccesForNonRegistered, setShowSuccessForNonRegistered] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [commitmentDetail, setCommitmentDetail] = useState(null)
    const [payOffline, setPayOffline] = useState(false);
    
    useEffect(() => {
        if (isEmailInvestment) {
            setOppDetail(location.state || {});
            setinvestmentAmount(minAmount);
        } else {
            getOpportunityDetails({ oppRid: oppRid })
                .then((res) => {
                    setOppDetail(res.data);
                    setinvestmentAmount(res.data.minAmount)
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [])

    const saveCommitment = () => {
        setIsLoading(true)

        const body = {
            commitment: investmentAmount,
            oppRid: oppRid,
            token: isEmailInvestment ? token : null
        }
        if (iid === 0 || iid === null) {
            setIsLoading(true)
            apiSaveNonRegisteredCommitment(body)
                .then((data => {
                    setIsLoading(false)
                    if (data) {
                        history.replace("/success-lp", { "alertTitle": data.message });
                    }
                }))
        } else if (isEmailInvestment) {
            setIsLoading(true)
            apiSaveRegisteredCommitment(body)
                .then(res => {
                    setIsLoading(false);
                    if (res) {
                        setShowDrawdown(true);
                        setIoRid(res.ioRid);
                        setDrawdownData(res);
                    }
                })
                .catch(e => {
                    setIsLoading(false);
                    setShowSuccessForNonRegistered(true);
                    setErrorMessage(e || 'An unknown error occurred.');
                })
        } else {
            saveInvestment(body)
                .then(data => {
                    setIsLoading(false)
                    setShowDrawdown(true)
                    setIoRid(data.ioRid)
                    setDrawdownData(data)
                }).catch(e => {
                    showSnackbar(e)
                }).finally(() => {
                    setSaving(false)
                })
        }
    }

    const onConfirm = () => {
        setIsLoading(true);
        if (!+investmentAmount) {
            setInvestmentError("This feild cannot be empty")
            return
        }
        if (+investmentAmount < oppDetail.minAmount) {
            setInvestmentError(`Minimum Investment amount is ${toCompactPrice(oppDetail.minAmount)}`)
            return
        }
        saveCommitment();
    }

    const onInputChange = (e) => {
        setinvestmentAmount(e.target.value)
        setInvestmentError(null)
    }

    if (showSuccess)
        return (
            <>
                <div >
                    <NavBar />
                    <div className="container mt-3">
                        <p className="fs-title-lg-semibold fw-bold text-center mb-2">{commitmentDetail.oppTitle}</p>
                        <p className="fs-title-semibold text-center">Thank you for your Investment</p>
                        {payOffline ?
                            <div className="d-flex justify-content-center">

                                <div className="d-flex flex-row justify-content-center border rounded mx-auto w-50 p-4">
                                    <table>
                                        <tr>
                                            <td>
                                                <p className="fs-lg-bold text-center p-0 m-0"><b>The wiring instructions are as follows:</b></p>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">Name</p></td>
                                            <td><p className="p-0 m-0">: SALIL CHAKRABARTY INNOVATION FUND</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">Bank</p></td>
                                            <td><p className="p-0 m-0">: RBL BANK</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">For credit to A/c No</p></td>
                                            <td><p className="p-0 m-0">: 409001821513</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">IFSC</p></td>
                                            <td><p className="p-0 m-0">: RATN0000116</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">SWIFT</p></td>
                                            <td><p className="p-0 m-0">: RATNINBB</p></td>
                                        </tr>
                                        <tr>
                                            <td><p className="p-0 m-0">TYPE</p></td>
                                            <td><p className="p-0 m-0">: Current Account</p></td>
                                        </tr>
                                    </table>
                                </div>

                            </div> : null}
                        <div className="mt-2">
                            <p className="fs-med-semibold text-center">
                                You will receive an email once the funds are deployed
                            </p>
                        </div>
                        <div className="row justify-content-center">
                            <button onClick={() => history.replace("/user/home")} className="btn btn-primary w-auto px-4">
                                Explore Other Opportunities
                            </button>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        )

    if (showContactTeam)
        return (
            <>
                <div >
                    {isEmailInvestment ? '' : <NavBar />}
                    {(!isLoading && oppDetail) && <div className="container mt-5">
                        <p className="fs-title-lg-semibold fw-bold text-center mb-4">{oppDetail.oppTitle}</p>
                        <div className="row mb-4">
                            <div className="p-5 border border-success rounded d-flex gap-1 flex-column align-items-center">
                                <span className="fs-title-semibold">Your Request Is Under Process</span>
                                <span className="fs-med-regular">
                                    Our Representative will contact you soon
                                </span>
                            </div>
                        </div>
                    </div>
                    }
                    <Footer />
                </div>
            </>
        )

    if (showDrawdown)
        return (
            <DrawDownComponent isEmailInvestment={isEmailInvestment}  path={drawdownData?.drawdown.path} oppDetail={oppDetail} token={token} ioRid={ioRid}/>
        )
    if (showSuccesForNonRegistered)

        return (
            <div className="container card-flat p-4 p-md-5">
                <img src={"/logo2.svg"} alt={"logo"} />

                <div className="loading-page">
                    {

                        isLoading ? <div className="loading-spinner"></div> : <ReportIcon sx={{ fontSize: 80 }} color="action" />
                    }
                    <p className="loading-text">
                        {
                            isLoading ? 'Loading...' : errorMessage
                        }
                    </p>
                </div>
            </div>
        )
    if (isLoading) {
        return (<div className="loading-page">
            <div className="loading-spinner"></div>
            <p className="loading-text">Loading...</p>
        </div>)
    }

    return (
        <>
            <div >
                {isEmailInvestment ? '' : <NavBar />}
                {(!isLoading && oppDetail) && <div className="container mt-5">
                    <p className="fs-title-lg-semibold fw-bold text-center mb-5">{showDrawdown ? 'Drawdown - ' : ''}{oppDetail.oppTitle}</p>
                    <div className="row mb-5">
                        <div className="col-12">
                            <div className="p-5 mb-5 border rounded d-flex flex-column align-items-center ">
                                <div className="col-3 px-0">
                                    <div className={`form-floating my-4`}>
                                        <input
                                            className={`form-control form-control-lg ${investmentError ? 'is-invalid' : ''}`}
                                            placeholder="Investment Amount"
                                            onChange={onInputChange}
                                            name="investmentAmount"
                                            value={investmentAmount}
                                            id="investmentAmount"
                                        />
                                        <label htmlFor="investmentAmount" className='pt-2 pb-2'>
                                            <span className="fs-large-semibold text-dark">Investment Amount</span>
                                        </label>

                                        <div id="investmentAmount" className="invalid-feedback">
                                            {investmentError}
                                        </div>
                                        {/* {walletAmount > 0?
                                        <Box className='mt-3' sx={{ border: '1px solid #ccc', borderRadius: '4px', padding: '16px' }}>
                                        <div className='d-flex flex-row gap-3'>
                                            <p className='fs-med-semibold'>Wallet Balance</p>
                                            <p className='fs-med-semibold'>: {formattedCurrency(walletAmount)}</p>
                                        </div>
                                        <FormControl component="fieldset" fullWidth>
                                            <FormGroup >
                                                <FormControlLabel
                                                    control={<Checkbox checked={walletOption} onChange={handleWalletOption} />}
                                                    label="Use Wallet Balance"
                                                    labelPlacement='end'
                                                />
                                            </FormGroup>
                                        </FormControl>
                                    </Box>:null} */}
                                    </div>
                                    <div className="row justify-content-end">
                                        {isEmailInvestment && !getUserAuth() ? <button disabled={saving} onClick={() => history.push('/')} className="btn w-auto px-4 me-3">
                                            Cancel
                                        </button> : <button disabled={saving} onClick={() => history.goBack()} className="btn w-auto px-4 me-3">
                                            Cancel
                                        </button>}

                                        <button disabled={saving} onClick={onConfirm} className="btn btn-primary w-auto px-4">
                                            {saving ? 'Loading' : 'Confirm'}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                }
                <Footer />
            </div>
        </>
    )
}

export default InvestNow